// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
import { getStorage } from 'firebase/storage';
import { getFirestore } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyBKfocQV2Uf48hdzbfx39IiS23VpfP5rds",
  authDomain: "sajalsoumalya.firebaseapp.com",
  projectId: "sajalsoumalya",
  storageBucket: "sajalsoumalya.appspot.com",
  messagingSenderId: "988549517716",
  appId: "1:988549517716:web:3f2d43d45f01552ca63f81",
  measurementId: "G-2N1YEXR7TF"
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
export const storage = getStorage();
export const db = getFirestore();
export const auth = getAuth();


